import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SlideData from "../data/Slide";
import SlideSection from "../components/SlideSection";

const HomePage = () => {
  useEffect(() => {
    // 특정 도메인 및 경로 감지
    if (
      window.location.hostname === "hereweare.io" &&
      window.location.pathname === "/"
    ) {
      document.documentElement.setAttribute("data-domain-style", "true");
    } else {
      document.documentElement.removeAttribute("data-domain-style");
    }
  }, []);

  return (
    <div className="App">
      <Header />

      <main>
        {SlideData &&
          SlideData.length > 0 &&
          SlideData.map((slide, index) => {
            return <SlideSection key={index} content={slide} idx={index} />;
          })}
      </main>

      <Footer />
    </div>
  );
};

export default HomePage;
