import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import YouTube from "react-youtube";

interface VideoPlayerProps {
  title: string; // 영상 타이틀
  ytId: string; // video 객체에 id 값이 포함되어야 함
  thumbUrl: string; // video 객체에 id 값이 포함되어야 함
}

export const YoutubePlayer: React.FC<VideoPlayerProps> = ({
  title,
  ytId,
  thumbUrl,
}) => {
  const [showPlayer, setShowPlayer] = useState(false);
  const [playStatus, setPlayStatus] = useState(false);
  const ref = useRef<ReactPlayer | null>(null);

  const opts = {
    height: "100%",
    width: "100%",
  };

  const onPlay = () => {
    setPlayStatus(true);
  };

  const onPause = () => {
    setPlayStatus(false);
  };

  const onEnded = () => {
    setPlayStatus(false);
  };

  const onClickVideo = () => {
    try {
      if (!showPlayer) {
        setShowPlayer(true); // 처음 클릭 시 플레이어 렌더링
        return;
      }

      if (ref.current) {
        const player = ref.current.getInternalPlayer();
        if (player.getPlayerState() === 1) {
          player.pauseVideo();
          setPlayStatus(false);
        } else {
          player.playVideo();
        }
      }
    } catch (e) {
      console.log("# VideoPlayer onClickVideo error ", e);
    }
  };

  /*
    Youtube 활성화되면 drag 안되는 문제를 수정.
      1. ReactPlayer에 pointerEvents: None 설정하여, 아래 포인터 이벤트를 받지 못하게 함. 
      2. 상위 div 에서 click Event를 받아, ReactPlayer의 재생상태를 파악하여, 재생 또는 Pause함. ref.current.getInternalPlayer 로 player 얻음 
          Youtube player API: https://developers.google.com/youtube/iframe_api_reference?hl=ko#Loading_a_Video_Player
      이렇게 해서 드래그는 잘 되는데, 문제점은... Youtube의 제목을 클릭해서 Youtube로 넘어가거나, 전체화면 등을 못하는 문제가 있음. 
    */
  return (
    <div onClick={onClickVideo} className="video-player">
      {
        <YouTube
          // ref={ref}
          // url={`${url}`}
          // playing={showPlayer}
          videoId={ytId}
          onPlay={onPlay}
          onPause={onPause}
          onEnd={onEnded}
          opts={opts}
        />
      }
    </div>
  );
};
