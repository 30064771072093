import React from "react";
import Animation from "./Animation";
import { TitleMapper } from "./Mapper";
import { SlideDataType } from "../data/Slide";
import "../styles/SlideSection/SlideSection.scss";
import { useMediaQuery } from "react-responsive";

export default function SlideSection({
  content,
  idx,
}: {
  content: SlideDataType;
  idx: number;
}) {
  const { title, mobTitle, desc } = content;

  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <section className="slideSection" id={`section_${idx}`} data-idx={idx}>
      <div className="layout">
        <div className="text">
          <TitleMapper content={isMobile ? mobTitle : title} />
          <p>{desc}</p>
        </div>
        <Animation idx={idx} />
      </div>
      <div className="bg bg1"></div>
      {/* // idx가 0일 때만 bg2, bg3 추가, 배경전환 효과용 */}
      {idx === 0 && (
        <>
          <div className="bg bg2" />
          <div className="bg bg3" />
          <div className="bg bg4" />
        </>
      )}
    </section>
  );
}
