import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import {
  DataProps,
  PerformanceDataProps,
  userContext,
} from "../../ProfilePage";
import { API_SERVER_ENDPOINT } from "../../../utils/axios";

export const Performances = () => {
  const userInfo = useContext<DataProps | null>(userContext);

  // 영상 정보
  const [performanceInfo, setPerformanceInfo] = useState<
    PerformanceDataProps[] | null
  >([]);

  const setData = () => {
    try {
      if (userInfo) {
        setPerformanceInfo(userInfo.performance);
      }
    } catch (e) {
      console.log("# Performance SetData error ", e);
    }
  };

  useEffect(() => {
    setData();
  }, [userInfo]);

  return (
    <div className="spot">
      {performanceInfo && (
        <>
          <div className="title">
            <p>Performance Archieve</p>
          </div>

          <div className="list">
            {performanceInfo &&
              performanceInfo.map((p) => (
                <div className="spot-item" key={p.idx}>
                  <div className="spot-image">
                    <img
                      alt="Img"
                      src={
                        API_SERVER_ENDPOINT + "/oapi/spot/perf/i?pi=" + p.idx
                      }
                    />
                  </div>
                  <div className="spot-info">
                    <div className="spot-title">{p.perf_name}</div>
                    {p.casting_name && (
                      <div className="desc">{p.casting_name}</div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};
