import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.css";
import { DataProps, VideoDataProps, userContext } from "../../ProfilePage";
import { Video } from "./components/video_item";

export const Videos = () => {
  const userInfo = useContext<DataProps | null>(userContext);
  // 영상 정보
  const [videoInfo, setVideoInfo] = useState<VideoDataProps[] | null>([]);

  const setData = () => {
    try {
      if (userInfo) {
        setVideoInfo(userInfo.video);
      }
    } catch (e) {
      console.log("# VideoInfo SetData error ", e);
    }
  };

  useEffect(() => {
    setData();
  }, [userInfo]);

  const isMultiple = videoInfo && videoInfo?.length >= 2;
  return (
    <div className={`video-wrapper ${isMultiple ? "" : "single-video"}`}>
      {isMultiple ? (
        <div className="list">
          {videoInfo.map((video) => {
            return (
              <Video key={video.idx} video={video} isSingle={false}></Video>
            );
          })}
        </div>
      ) : (
        videoInfo &&
        videoInfo.length > 0 && (
          <Video video={videoInfo[0]} isSingle={true}></Video>
        )
      )}
    </div>
  );
};
