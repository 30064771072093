import React from "react";
import "../styles/SlideSection/SlideSection.scss";

export function TitleMapper({ content }: { content: string[] }) {
  return (
    <div className="title">
      {content.map((title, index) => {
        // "^H"로 시작하면 제거 후 강조 스타일 적용
        const isHighlighted = title.startsWith("^H");
        const cleanText = isHighlighted ? title.slice(2) : title;

        // "\n"을 <br />로 변환
        const formattedText = cleanText.split("\n").map((line, i) => (
          <React.Fragment key={i}>
            {i > 0 && <br />}
            {line}
          </React.Fragment>
        ));

        return isHighlighted ? (
          <span key={index} className="textHighlight">
            {formattedText}
          </span>
        ) : (
          <span key={index}>{formattedText}</span>
        );
      })}
    </div>
  );
}
