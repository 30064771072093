import React, { useContext } from "react";
import "./style.css";
import { Videos } from "../video";
import { Performances } from "../performance";
import { DataProps, userContext } from "../../ProfilePage";

export const Contents = () => {
  const userInfo = useContext<DataProps | null>(userContext);

  return (
    <>
      {(userInfo?.video || userInfo?.performance) && (
        <>
          <div className="content-wrapper">
            <Videos />

            {userInfo?.video && userInfo?.performance && (
              <div className="divider" />
            )}

            <Performances></Performances>
          </div>
        </>
      )}
    </>
  );
};
