import React, { useEffect, useState } from "react";
import "./style.css";
import more from "../../../assets/profile/iconMore.svg";
import logo from "../../../assets/profile/logo.png";

interface Props {
  className: any;
}

export const Bottombar = ({ handle }: { handle: string }) => {
  const [dynamicLink, setDynamicLink] = useState<string>("");

  const moveToDynamicLink = () => {
    window.open(dynamicLink, "_blank");
  };

  const encodedParamSymbol = "%3F";
  const encodedEqualSymbol = "%3D";

  const setPram = (param: string, value: string) => {
    return encodedParamSymbol + param + encodedEqualSymbol + value;
  };

  useEffect(() => {
    var link = "https://link.hereweare.io/";
    link += "?link=https://hereweare.io/pArts/userHome";
    link += setPram("handle", handle);
    link += "&apn=com.hsoft.hwa&isi=1491869024&ibi=com.hsoft.hwa&efr=1";

    setDynamicLink(link);
  }, []);

  return (
    <div className="footer">
      <a href="https://link.hereweare.io/install" target="_blank">
        <div className="link">
          <img className="icon-logo" alt="footer-logo" src={`${logo}`} />

          <div className="move">
            <p>Create my profile</p>
            <img src={`${more}`} />
          </div>
        </div>
      </a>

      <div className="button-div">
        <button
          className="connect-button"
          type="button"
          onClick={moveToDynamicLink}
        >
          <p>Connect with me!</p>
        </button>
      </div>
    </div>
  );
};
