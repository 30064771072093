import React from "react";
import { Routes, Route } from "react-router-dom";
import ProfilePage from "./profile/ProfilePage";
import HomePage from "./page";

const RouterList = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/profile/:id" element={<ProfilePage />} />
    </Routes>
  );
};

export default RouterList;
