import React from "react";
import "./style.css";
import instagram from "../../../assets/profile/instagram.svg";
import youtube from "../../../assets/profile/youtube.svg";
import facebook from "../../../assets/profile/facebook.svg";
import tiktok from "../../../assets/profile/tiktok.svg";
import link from "../../../assets/profile/link.svg";
import badge from "../../../assets/profile/badge.svg";
import share from "../../../assets/profile/share.svg";
import { useRef, useContext } from "react";
import { DataProps, userContext } from "../../ProfilePage";
import { API_SERVER_ENDPOINT } from "../../../utils/axios";

export const ProfileInfo = () => {
  const userInfo = useContext<DataProps | null>(userContext);

  const profileRef = useRef<HTMLDivElement>(null);

  const formatUrl = (url: string) => {
    // 만약 http:// 또는 https:// 가 없다면, https:// 를 추가
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  const getProfileUrl = () => {
    if (!userInfo?.user.handle) return "";

    var url =
      API_SERVER_ENDPOINT + "/oapi/user/pi?handle=" + userInfo?.user.handle;

    if (userInfo?.user.profile_picture_update_ts)
      url += "&d=" + userInfo?.user.profile_picture_update_ts;
    return url;
  };

  const shareLink = async () => {
    const url = window.location.href;
    const title = document.title;

    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          url: url,
        });
      } catch (error) {
        console.log("Share Canceled: ", error);
      }
    } else {
      // Web Share API 미지원 브라우저 대응 (클립보드 복사 fallback)
      try {
        await navigator.clipboard.writeText(url);
        alert("URL copied! Try pasting it.");
      } catch (error) {
        console.log("Copy Canceled: ", error);
      }
    }
  };

  return (
    <div
      className={`profile ${
        userInfo?.video && userInfo?.performance ? "" : "empty-contents"
      }`}
      ref={profileRef}
    >
      <div className="blured-bg">
        <img className="background-img" alt="Rectangle" src={getProfileUrl()} />

        <div className="blur-div" />
      </div>

      {userInfo && (
        <div className="contents">
          <img className="profile-img" alt="Rectangle" src={getProfileUrl()} />

          <div className="info">
            <div className="name">
              <p>{userInfo.user?.nickname}</p>

              <div className="badge">
                <img className="badge-img" src={`${badge}`} />
              </div>
            </div>

            {userInfo.user?.sub_nickname && (
              <p className="full-name">{userInfo.user?.sub_nickname}</p>
            )}
          </div>

          {userInfo.user?.description && (
            <p className="intro">{userInfo.user?.description}</p>
          )}

          {userInfo.link && (
            <div className="sns">
              {userInfo.link.map((l) => {
                let imgSrc = "";
                let altText = "";

                // l_type에 맞는 img src와 alt 설정
                switch (l.l_type) {
                  case "IG":
                    imgSrc = `${instagram}`;
                    altText = "Instagram";
                    break;
                  case "YT":
                    imgSrc = `${youtube}`;
                    altText = "YouTube";
                    break;
                  case "FB":
                    imgSrc = `${facebook}`;
                    altText = "Facebook";
                    break;
                  case "TIKTOK":
                    imgSrc = `${tiktok}`;
                    altText = "TikTok";
                    break;
                  default:
                    imgSrc = `${link}`; // 기본 아이콘
                    altText = "Social Media";
                    break;
                }

                // 링크를 처리
                const formattedUrl = formatUrl(l.l_url);

                return (
                  <div className="sns-button" key={l.idx}>
                    <a
                      href={formattedUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img className="sns-img" alt="Img" src={imgSrc} />
                    </a>
                  </div>
                );
              })}
            </div>
          )}

          <button className="share-button" onClick={shareLink}>
            <img className="share-img" src={`${share}`}></img>
          </button>
        </div>
      )}
    </div>
  );
};
