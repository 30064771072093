import React from "react";
import "./style.css";
import "./styleGuide.css";
import { Bottombar } from "./components/bottom_bar";
import { ProfileInfo } from "./components/profile_info";
import { Contents } from "./components/content";
import { useLocation } from "react-router-dom";
import { useEffect, useState, createContext } from "react";
import apiInstance from "../utils/axios";

export const userContext = createContext<DataProps | null>(null);

// 유저 정보
export interface UserDataProps {
  idx: number;
  nickname: string;
  sub_nickname?: string; // Full Name (활동명)
  description?: string; // 소개
  handle: string; // handle
  profile_picture_update_ts?: number; // Profile Update Ts
}

// 링크 정보
export interface LinkDataProps {
  idx: number;
  title?: string; // 링크 이름
  l_url: string; // 링크
  l_type?: "OTHER" | "IG" | "YT" | "FB" | "TIKTOK"; // 링크 타입
}

// 영상 정보
export interface VideoDataProps {
  idx: number;
  title: string; // 영상 제목
  v_type?: "UPLOAD" | "YT" | undefined; // 영상 타입
  v_url?: string; // 외부 링크
}

// 퍼포먼스 정보
export interface PerformanceDataProps {
  idx: number;
  perf_name: string; // 퍼포먼스 제목
  casting_name: string; // 역할명
}

export interface DataProps {
  user: UserDataProps;
  link: LinkDataProps[];
  video: VideoDataProps[];
  performance: PerformanceDataProps[];
}

const ProfilePage = () => {
  const location = useLocation();
  const handle = location.pathname.split("/").filter(Boolean).pop();

  // 유저 정보, 하위 컴포넌트에 컨텍스트로 전달
  const [info, setInfo] = useState<DataProps | null>(null);

  const setProfileData = () => {
    var url = "/oapi/user?handle=" + handle;

    const moveToHome = () => {
      // 홈으로 Redirection
      const hostUrl = window.location.origin;

      // 상대 경로를 제거하고 홈으로 이동
      window.location.href = hostUrl;
    };

    apiInstance
      .get(url, { isMsg: true })
      .then((res) => {
        try {
          // console.log("res ? ", res);
          if (res.data.is_success && res.data.value) {
            setInfo({
              user: {
                ...res.data.value,
                handle: handle,
              },
              link: res.data.value.user_link_list,
              video: res.data.value.user_video_list,
              performance: res.data.value.perf_list,
            });
          }
        } catch (e) {
          console.log("ProfilePage setProfileData error ? ", e);
          moveToHome();
        }
      })
      .catch((err) => {
        console.log("ProfilePage setProfileData api error ? ", err);
        moveToHome();
      });
  };

  useEffect(() => {
    setProfileData();
  }, []);

  useEffect(() => {}, [info]);

  return (
    <userContext.Provider value={info}>
      <div className="div-wrapper">
        <div className="div">
          <div className="contents-body">
            <ProfileInfo></ProfileInfo>

            <Contents></Contents>
          </div>

          {handle && <Bottombar handle={handle} />}
        </div>
      </div>
    </userContext.Provider>
  );
};

export default ProfilePage;
