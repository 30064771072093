import axios, { AxiosRequestConfig } from "axios";
// export const API_SERVER_ENDPOINT = "http://192.168.0.43/devapi";
// export const MSG_SERVER_ENDPOINT = "http://192.168.0.43/devmsg";
export const API_SERVER_ENDPOINT = "https://api2.hereweare.io";
export const MSG_SERVER_ENDPOINT = "https://msg2.hereweare.io";

class ApiInstanceClass {
  instance;

  constructor() {
    this.instance = axios.create({
      withCredentials: true,
      timeout: 3000,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": `http://localhost:3001`,
        "Access-Control-Allow-Credentials": true,
      },
    });
  }

  request(params: AxiosRequestConfig) {
    return this.instance.request(params);
  }

  get(
    url: string,
    type?: { isMsg: boolean },
    params?: { [key: string]: string }
  ) {
    return this.instance.get(
      (type?.isMsg ? MSG_SERVER_ENDPOINT : API_SERVER_ENDPOINT) + url,
      params
    );
  }

  post(
    url: string,
    type?: { isMsg: boolean },
    body?: any,
    params?: Record<string, any>
  ) {
    return this.instance.post(
      (type?.isMsg
        ? process.env.NEXT_PUBLIC_MSG_SERVER_ENDPOINT
        : process.env.NEXT_PUBLIC_API_SERVER_ENDPOINT) + url,
      body,
      { params }
    );
  }

  put(url: string, body: any, type?: { isMsg: boolean }) {
    return this.instance.put(
      (type?.isMsg
        ? process.env.NEXT_PUBLIC_MSG_SERVER_ENDPOINT
        : process.env.NEXT_PUBLIC_API_SERVER_ENDPOINT) + url,
      body
    );
  }

  del(url: string, body: { [key: string]: string }, type?: { isMsg: boolean }) {
    return this.instance.delete(
      (type?.isMsg
        ? process.env.NEXT_PUBLIC_MSG_SERVER_ENDPOINT
        : process.env.NEXT_PUBLIC_API_SERVER_ENDPOINT) + url,
      body
    );
  }
}

const apiInstance = new ApiInstanceClass();

export default apiInstance;
