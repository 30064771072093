import React, { useEffect, useRef } from "react";
import initAnimation from "./utils/initAnimation";
import { BrowserRouter as Router } from "react-router-dom";
import RouterList from "./Router";

function App() {
  const currIndex = useRef(0);
  useEffect(() => initAnimation(currIndex), []);

  return (
    <Router>
      <RouterList />
    </Router>
  );
}

export default App;
