class YoutubeUtils {
  convertUrlToId(url: string): string | null {
    try {
      if (!url.includes("http") && url.length === 11) {
        return url; // 이미 ID인 경우
      }

      // url 트리밍
      url = url.trim();

      // 정규 표현식 배열
      const regexps = [
        /^https:\/\/(?:www\.|m\.)?youtube\.com\/watch\?v=([_\-a-zA-Z0-9]{11}).*$/,
        /^https:\/\/(?:www\.|m\.)?youtube(?:-nocookie)?\.com\/embed\/([_\-a-zA-Z0-9]{11}).*$/,
        /^https:\/\/youtu\.be\/([_\-a-zA-Z0-9]{11}).*$/,
        /^https:\/\/(?:www\.|m\.)?youtube\.com\/shorts\/([_\-a-zA-Z0-9]{11}).*$/,
      ];

      // 정규 표현식 순회하여 매칭
      for (const exp of regexps) {
        const match = url.match(exp);
        if (match && match[1]) {
          return match[1]; // ID 반환
        }
      }

      return null; // 매칭되지 않으면 null 반환
    } catch (e) {
      console.log("# YoutubeService convertUrlToId error", e);
      return null;
    }
  }

  getThumbnailUrl(videoId: string): string {
    return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
  }
}

const youtubeInstance = new YoutubeUtils();

export default youtubeInstance;
