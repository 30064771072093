import React, { useEffect, useState } from "react";
import { VideoDataProps } from "../../../../ProfilePage";
import { API_SERVER_ENDPOINT } from "../../../../../utils/axios";
import youtubeInstance from "../../../../../utils/youtube";
import { YoutubePlayer } from "../youtube_player";
import { CommonVideoPlayer } from "../common_video_player";

export const Video = ({
  video,
  isSingle,
}: {
  video: VideoDataProps;
  isSingle: boolean;
}): JSX.Element => {
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [thumbnailUrl, setThumbnailUrl] = useState<string>("");
  const [ytId, setYtUrl] = useState<string>("");

  const setData = () => {
    switch (video.v_type) {
      case "YT":
        // 유튜브 링크 설정
        if (video.v_url) {
          setVideoUrl(video.v_url); // 비디오 링크 설정

          // 썸네일 설정
          const videoId = youtubeInstance.convertUrlToId(video.v_url!);
          if (videoId) {
            setYtUrl(videoId);
            setThumbnailUrl(youtubeInstance.getThumbnailUrl(videoId));
          }
        }

        break;
      default:
        // 스트리밍 링크
        setVideoUrl(API_SERVER_ENDPOINT + "/oapi/spot/v/s?vi=" + video.idx);
        // 썸네일 링크
        setThumbnailUrl(API_SERVER_ENDPOINT + "/oapi/spot/v/i?vi=" + video.idx);
        break;
    }
  };

  useEffect(() => {
    setData();
  }, []);

  return (
    <div className={`video ${isSingle ? "isSingle" : "isMulti"}`}>
      {video.v_type === "YT" ? (
        <YoutubePlayer
          title={video.title}
          ytId={ytId}
          thumbUrl={thumbnailUrl}
        ></YoutubePlayer>
      ) : (
        <CommonVideoPlayer
          title={video.title}
          url={videoUrl}
          thumbUrl={thumbnailUrl}
        ></CommonVideoPlayer>
      )}
    </div>
  );
};
