import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";

interface VideoPlayerProps {
  title: string; // 영상 타이틀
  url: string; // video 객체에 id 값이 포함되어야 함
  thumbUrl: string; // video 객체에 id 값이 포함되어야 함
}

export const CommonVideoPlayer: React.FC<VideoPlayerProps> = ({
  title,
  url,
  thumbUrl,
}) => {
  const [showPlayer, setShowPlayer] = useState(false);
  const [playStatus, setPlayStatus] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const onClickVideo = () => {
    try {
      if (!showPlayer) {
        setShowPlayer(true); // 처음 클릭 시 플레이어 렌더링

        setPlayStatus(true);
        return;
      }

      if (!playStatus) {
        setPlayStatus(true);
        videoRef.current?.play();
      } else {
        videoRef.current?.pause();
        setPlayStatus(false);
      }
    } catch (e) {
      console.log("# VideoPlayer onClickVideo error ", e);
    }
  };

  // showPlayer가 true로 변경된 후 실행
  useEffect(() => {
    if (showPlayer && videoRef.current) {
      videoRef.current.play();
    }
  }, [showPlayer]);

  /*
    Youtube 활성화되면 drag 안되는 문제를 수정.
      1. ReactPlayer에 pointerEvents: None 설정하여, 아래 포인터 이벤트를 받지 못하게 함. 
      2. 상위 div 에서 click Event를 받아, ReactPlayer의 재생상태를 파악하여, 재생 또는 Pause함. ref.current.getInternalPlayer 로 player 얻음 
          Youtube player API: https://developers.google.com/youtube/iframe_api_reference?hl=ko#Loading_a_Video_Player
      이렇게 해서 드래그는 잘 되는데, 문제점은... Youtube의 제목을 클릭해서 Youtube로 넘어가거나, 전체화면 등을 못하는 문제가 있음. 
    */
  return (
    <div onClick={onClickVideo} className="video-player">
      {!showPlayer ? (
        <div className="thumbnail-div">
          <img className="bg-image" alt="Background" src={`${thumbUrl}`} />

          <div className="text video-2">
            <div className="video-title">{title}</div>
          </div>

          <img
            className="icon"
            alt="Icon"
            src="https://c.animaapp.com/oaq7Ognj/img/icon-1.svg"
          />
        </div>
      ) : (
        <video ref={videoRef} width="100%" height="100%" controls>
          <source src={url} type="video/mp4" />
        </video>
      )}
    </div>
  );
};
